import classNames from "classnames";
import { Link } from "react-router-dom";
import { useLoginState } from "@ugg/shared/hooks/use-user-hooks";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { ReactComponent as UGGLogo } from "@ugg/shared/assets/svg/logos/ugg-logo-blue.svg";
import { ReactComponent as Sparkle } from "@ugg/shared/assets/svg/sparkle.svg";

export function AdblockAdUnit({ className, utm }: { className?: string; utm?: Record<string, string> }) {
  const isLoggedIn = useLoginState();
  const { data } = useUserPremiumState();
  const appPageParams = new URLSearchParams(utm);

  const adFreePath =
    isLoggedIn && !data?.isPremium
      ? { pathname: AppRoutes.PREMIUM_CHECKOUT, search: "planType=1-months" }
      : { pathname: AppRoutes.SIGNUP, search: "continue=/purchase/1-months&type=premium_boost" };

  return (
    <div className={classNames("w-[300px] rounded-[2px] font-inter overflow-hidden", className)}>
      <div className="flex justify-between p-[5px] bg-purple-400">
        <div className="text-accent-gray-100">U.GG</div>
        <Link to={adFreePath}>
          <div className="flex items-center text-accent-yellow-500 font-semibold">
            <Sparkle className="mr-[4px] w-[12px]" /> <span>Go Ad-Free</span>
          </div>
        </Link>
      </div>
      <div className="p-[24px] bg-purple-550">
        <UGGLogo className="w-[120px]" />
        <p className="mt-[24px] font-barlow text-[24px] text-white">
          We're happy to have you, <span className="font-semibold">adblock or not.</span>
        </p>
        <p className="mt-[24px] text-[14px] text-accent-gray-100">
          But, for those of you who want to support us a little more, these options go a long way in{" "}
          <span className="text-white font-semibold">keeping U.GG free:</span>
        </p>
        <div
          className={`
          mt-[24px] p-[18px] border-accent-gray-200 border-t-[2px] 
          bg-[linear-gradient(225deg,rgba(49,49,96,0.48)_0%,rgba(25,25,55,0.48)_126.21%)]
          drop-shadow-[0px_2px_4px_rgba(0,0,0,0.25)]
          [&>*:not(:first-child)]:border-t-[1px] [&>*:not(:first-child)]:border-accent-purple-700
          [&>*:not(:first-child)]:mt-[12px] [&>*:not(:first-child)]:pt-[12px]
        `}
        >
          <div className="text-[14px] text-accent-gray-100">
            <Link className="text-accent-blue-400 font-semibold underline" to={`${AppRoutes.UGG_APP_SPLASH}?${appPageParams}`}>
              Download our desktop app
            </Link>
            . <span className="text-white font-semibold">Fewer ads + more features, like build auto-import and overlays.</span>
          </div>
          <div className="text-[14px] text-accent-gray-100">
            <span>
              Disable ad block or add us to your{" "}
              <Link className="text-accent-blue-400 font-semibold underline" to={AppRoutes.ADBLOCK_WHITELIST}>
                Allow List
              </Link>
              .
            </span>
          </div>
          <div className="text-[14px] text-accent-gray-100">
            <span>
              Support us by going{" "}
              <Link className="text-accent-blue-400 font-semibold underline" to={adFreePath}>
                Ad Free
              </Link>
              .
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
